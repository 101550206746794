@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

/* @font-face{
    font-family: 'Nexa';
    src: url(
        './../public/fonts/nexa/Nexa-Heavy.ttf'
    );
}

@font-face{
    font-family: 'Nexa-Light';
    src: url(
        './../public/fonts/nexa/Nexa-ExtraLight.ttf'
    );
} */